import { Injectable } from '@angular/core';
import {
  AuthStateChange,
  ConfirmVerificationCodeOptions,
  FirebaseAuthentication,
  GetCurrentUserResult,
  GetIdTokenOptions,
  GetIdTokenResult,
  LinkResult,
  LinkWithOAuthOptions,
  PhoneCodeSentEvent,
  PhoneVerificationCompletedEvent,
  PhoneVerificationFailedEvent,
  SignInResult,
  SignInWithCustomTokenOptions,
  UnlinkOptions,
  UnlinkResult
} from '@capacitor-firebase/authentication';
import { SetLanguageCodeOptions } from '@capacitor-firebase/authentication/dist/esm/definitions';
import { PluginListenerHandle } from '@capacitor/core';
import { defer, Observable, share, tap } from 'rxjs';

interface NativePhoneVerificationCompletedEvent {
  type: 'phoneVerificationCompleted';
  event: PhoneVerificationCompletedEvent;
}

interface NativePhoneVerificationFailedEvent {
  type: 'phoneVerificationFailed';
  event: PhoneVerificationFailedEvent;
}

interface NativePhoneCodeSentEvent {
  type: 'phoneCodeSent';
  event: PhoneCodeSentEvent;
}

interface NativeAuthStateChange {
  type: 'authStateChange';
  event: AuthStateChange;
}

type NativeEvent =
  NativePhoneVerificationCompletedEvent
  | NativePhoneVerificationFailedEvent
  | NativeAuthStateChange
  | NativePhoneCodeSentEvent;
let count = 0;

@Injectable({ providedIn: 'root' })
export class IonicNativeAuthService {
  private resendCode = false;
  public readonly nativeEvent$ = new Observable<NativeEvent>(observer => {
    console.log('nativeEvent$', count++);
    FirebaseAuthentication.addListener('phoneVerificationCompleted', event => observer.next({
      type: 'phoneVerificationCompleted', event
    }));
    FirebaseAuthentication.addListener('phoneVerificationFailed', event => observer.next({
      type: 'phoneVerificationFailed', event
    }));
    FirebaseAuthentication.addListener('phoneCodeSent', event => observer.next({
      type: 'phoneCodeSent', event
    }));
    FirebaseAuthentication.addListener('authStateChange', event => observer.next({
      type: 'authStateChange', event
    }));
    return async (): Promise<void> => {
      console.log('FirebaseAuthentication.addListener removeAllListeners');
      await FirebaseAuthentication.removeAllListeners();
      count--;
    };
  }).pipe(
    tap(e => {
      switch (e.type) {
        case 'phoneVerificationFailed':
          if (/re-send/.test(e.event.message)) {
            this.resendCode = true;
          }
          break;
        case 'phoneVerificationCompleted':
          console.log(e.event);
          break;
        case 'authStateChange':
          console.log(e.event);
          break;
      }
    }),
    share()
  );

  public get phoneVerificationCompleted$(): Observable<PhoneVerificationCompletedEvent> {
    return new Observable<PhoneVerificationCompletedEvent>(observer => {
      let handle: PluginListenerHandle | undefined;
      const subscription = defer(() => FirebaseAuthentication.addListener('phoneVerificationCompleted', event => {
        observer.next(event);
      })).pipe(
        tap(h => handle = h)
      ).subscribe();

      return async (): Promise<void> => {
        subscription.unsubscribe();
        await handle?.remove();
      };
    });
  }

  public signInWithPhoneNumber(phoneNumber: string, resendCode = false): Observable<void> {
    console.log('signInWithPhoneNumber', this.resendCode || resendCode);
    return defer(() => FirebaseAuthentication.signInWithPhoneNumber({ phoneNumber, resendCode: this.resendCode || resendCode, timeout: 0 }));
  }

  public linkInWithPhoneNumber(phoneNumber: string, resendCode = false): Observable<void> {
    console.log('linkInWithPhoneNumber', this.resendCode || resendCode);
    return defer(() => FirebaseAuthentication.linkWithPhoneNumber({ phoneNumber, resendCode: this.resendCode || resendCode, timeout: 0 }));
  }

  public confirmVerificationCode(options: ConfirmVerificationCodeOptions): Observable<SignInResult> {
    return defer(() => FirebaseAuthentication.confirmVerificationCode(options));
  }

  public signInWithGoogle(): Observable<SignInResult> {
    return defer(() => FirebaseAuthentication.signInWithGoogle());
  }

  public signInWithApple(): Observable<SignInResult> {
    return defer(() => FirebaseAuthentication.signInWithApple());
  }

  public getIdToken(options?: GetIdTokenOptions): Observable<GetIdTokenResult> {
    return defer(() => FirebaseAuthentication.getIdToken(options));
  }

  public setLanguageCode(options: SetLanguageCodeOptions): Observable<void> {
    return defer(() => FirebaseAuthentication.setLanguageCode(options));
  }

  public signOut(): Observable<void> {
    return defer(() => FirebaseAuthentication.signOut());
  }

  public signInWithCustomToken(options: SignInWithCustomTokenOptions): Observable<SignInResult> {
    return defer(() => FirebaseAuthentication.signInWithCustomToken(options));
  }

  public getCurrentUser(): Observable<GetCurrentUserResult> {
    return defer(() => FirebaseAuthentication.getCurrentUser());
  }

  public unlink(options: UnlinkOptions): Observable<UnlinkResult> {
    return defer(() => FirebaseAuthentication.unlink(options));
  }

  public linkWithGoogle(options?: LinkWithOAuthOptions): Observable<LinkResult> {
    return defer(() => FirebaseAuthentication.linkWithGoogle(options));
  }

  public linkWithApple(options?: LinkWithOAuthOptions): Observable<LinkResult> {
    return defer(() => FirebaseAuthentication.linkWithApple(options));
  }
}
