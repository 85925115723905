import { Location } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { defer, filter, map, Observable, of, skip, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HistoryService {
  private readonly router = inject(Router);
  private readonly location = inject(Location);
  private previousUrl: string | undefined;

  public get init$(): Observable<any> {
    return this.router.events.pipe(
      filter((e): e is NavigationStart => e instanceof NavigationStart),
      skip(1),
      tap(() => this.savePreviousUrl()),
    );
  }

  public back(defaultUrl?: string): Observable<void> {
    const targetUrl = this.previousUrl ?? defaultUrl;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this.location.getState()?.navigationId === 1 && targetUrl) {
      return defer(() => this.router.navigateByUrl(targetUrl)).pipe(map(() => undefined));
    } else {
      this.location.back();
      return of(undefined);
    }
  }

  public savePreviousUrl(): void {
    this.previousUrl = this.location.path();
  }
}
