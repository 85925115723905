import { Routes } from '@angular/router';
import { adminGuard } from '@pixels/client/guards/admin.guard';
import { alreadyLoggedInForAdminGuard } from '@pixels/client/guards/already-logged-in-for-admin.guard';
import { authGuard } from '@pixels/client/guards/auth.guard';
import { i18nGuard } from '@pixels/client/guards/i18n.guard';
import { adminRoutes } from '@pixels/client/pages/admin/admin.constant';
import { lazyLoadError } from '@pixels/client/router/lazy-load-error';
import { PATH_MAP } from './core/constant';

export const routes: Routes = [
  {
    path: PATH_MAP.login.path,
    canActivate: [i18nGuard, alreadyLoggedInForAdminGuard],
    loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage).catch(lazyLoadError)
  },
  {
    path: adminRoutes.admin.path,
    canActivate: [i18nGuard, authGuard, adminGuard],
    loadComponent: () => import('@pixels/client/pages/admin/admin.page').then(m => m.AdminPage).catch(lazyLoadError),
    loadChildren: () => import('@pixels/client/pages/admin/admin.routes').then(m => m.ADMIN_ROUTES).catch(lazyLoadError),
  },
  {
    path: PATH_MAP.releaseNote.path,
    canActivate: [i18nGuard, authGuard, adminGuard],
    loadComponent: () => import('./pages/release-note/release-note.page').then(m => m.ReleaseNotePage).catch(lazyLoadError)
  },
  {
    path: PATH_MAP.deploy.path,
    canActivate: [i18nGuard, authGuard, adminGuard],
    loadComponent: () => import('./pages/deploy/deploy.page').then(m => m.DeployPage).catch(lazyLoadError)
  },
  {
    path: 'screenshots',
    canActivate: [i18nGuard],
    loadChildren: () => import('./pages/screenshots/screenshots-routes').then(m => m.screenshotsRoutes).catch(lazyLoadError),
  },
  {
    path: 'i18n/generate-strings',
    canActivate: [i18nGuard],
    loadComponent: () => import('./pages/generate-strings/generate-strings.page').then(m => m.GenerateStringsPage).catch(lazyLoadError),
  },
  {
    path: '**',
    redirectTo: adminRoutes.admin.path,
    pathMatch: 'full'
  },
];
