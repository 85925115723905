import { registerLocaleData } from '@angular/common';
import localeKo from '@angular/common/locales/ko';
import { Component, inject, Injector, OnInit } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { AppInjector } from '@pixels/client/app-injector/app.injector';
import { LoadingContainerComponent } from '@pixels/client/components/loading-container/loading-container.component';
import { GlobalSpinnerV2Service } from '@pixels/client/global-spinner/global-spinner-v2.service';
import { registerDialogWithBackButton } from '@pixels/client/ionic/ionic-helper';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    LoadingContainerComponent,
    IonApp,
    IonRouterOutlet
  ],
  providers: [
    AppService,
    GlobalSpinnerV2Service
  ]
})
export class AppComponent implements OnInit {
  private readonly appService = inject(AppService);

  constructor(injector: Injector) {
    registerLocaleData(localeKo, 'ko');
    AppInjector.getInstance().setInjector(injector);
    registerDialogWithBackButton(injector);
  }

  public ngOnInit(): void {
    this.appService.init$.subscribe();
  }
}
