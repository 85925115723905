import { inject } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Role } from '@pixels/universal/model/user/role.enum';
import { defer, map, Observable, of, switchMap, take } from 'rxjs';

export const RETURN_URL_PARAM_KEY = 'returnUrl';

export function alreadyLoggedInForAdminGuard(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  const auth = inject(Auth);
  const router = inject(Router);

  return authState(auth).pipe(
    take(1),
    switchMap(user => user ? user.getIdTokenResult() : of(null)),
    switchMap(idTokenResult => {
      if (!(idTokenResult?.claims.roles as Role[])?.includes(Role.Admin)) {
        return of(true);
      }
      const returnUrl = next.queryParamMap.get(RETURN_URL_PARAM_KEY) ?? '/';
      return defer(() => router.navigateByUrl(returnUrl, { replaceUrl: true })).pipe(map(() => false));
    })
  );
}
