import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom, inject, LOCALE_ID } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, RouteReuseStrategy, withPreloading } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideNgIdle } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AppInjector } from '@pixels/client/app-injector/app.injector';
import { WINDOW } from '@pixels/client/dom/window-token';
import { SUPPORT_LANGUAGE_CODES_TOKEN } from '@pixels/client/i18n-util/i18n-util';
import { API_URL_TOKEN } from '@pixels/client/injection-tokens/api-url-token';
import { PROXY_URL_TOKEN } from '@pixels/client/injection-tokens/common-link-tokens';
import { PHASE_TOKEN } from '@pixels/client/injection-tokens/environment-token';
import { apiWrapperInterceptor } from '@pixels/client/interceptors/api-wrapper.interceptor';
import { tokenInterceptor } from '@pixels/client/interceptors/token.interceptor';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { initCapacitorAndAngular } from '@pixels/client/sentry/sentry-util';
import { PIXELS_CLIENT_DSN } from '@pixels/client/sentry/sentry.model';
import { isLocalHost } from '@pixels/client/utils/dev-tools';
import { PixelsAdminProjectMeta } from '@pixels/universal/model/apps/project-meta/pixels-admin-project-meta';
import { LANGUAGE_CODES_FOR_SCREENSHOTS } from '@pixels/universal/model/i18n/i18n.model';
import * as SentryAngular from '@sentry/angular';
import { DEFAULT_CONFIG, NgForageOptions } from 'ngforage';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AppI18nLoader } from './app/app-i18n-loader';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { PixelsAdminAppInfo } from './app/pixels-admin-app-info';
import { environment } from './environments/environment';

if (!isLocalHost() && environment.production) {
  initCapacitorAndAngular(PIXELS_CLIENT_DSN, `${environment.phase}-${PixelsAdminProjectMeta.folderName}`);
}
if (environment.production) {
  enableProdMode();
} else {
// 개발 환경에서는 서비스 워커 삭제
//   unregisterServiceWorkers();
}

const isNative = Capacitor.isNativePlatform();
const isIOS = Capacitor.getPlatform() === 'ios';
if (isNative) {
  const style = Capacitor.getPlatform() !== 'android' ? Style.Light : Style.Default;
  StatusBar.setStyle({ style });
}
bootstrapApplication(AppComponent, {
  providers: [
    provideIonicAngular({ swipeBackEnabled: isIOS && isNative }),
    provideAnimations(),
    provideNgIdle(),
    provideRouter(routes, withPreloading(QuicklinkStrategy)),
    provideHttpClient(withInterceptors([apiWrapperInterceptor, tokenInterceptor])),
    provideFirebaseApp(() => {
      const app = initializeApp(environment.firebase);
      if (isNative) {
        initializeAuth(app, { persistence: indexedDBLocalPersistence });
      }
      return app;
    }),
    provideAuth(() => {
      const auth = getAuth();
      if (!environment.production) {
        // @ts-ignore
        inject(WINDOW).signInWithCustomToken = async (customToken: string) => signInWithCustomToken(auth, customToken);
      }
      return auth;
    }),
    importProvidersFrom(
      QuicklinkModule,
      TranslateModule.forRoot({ loader: { provide: TranslateLoader, useClass: AppI18nLoader } }),
      MatSnackBarModule,
      MatDialogModule,
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'ko' },
    { provide: SUPPORT_LANGUAGE_CODES_TOKEN, useValue: LANGUAGE_CODES_FOR_SCREENSHOTS },
    { provide: API_URL_TOKEN, useValue: environment.apiUrl },
    { provide: PROXY_URL_TOKEN, useValue: environment.proxyUrl },
    { provide: PHASE_TOKEN, useValue: environment.phase },
    { provide: DEFAULT_CONFIG, useValue: { name: `pixels-admin-${environment.phase}`, } as NgForageOptions },
    { provide: AppInfo, useClass: PixelsAdminAppInfo },
    { provide: ErrorHandler, useValue: SentryAngular.createErrorHandler({ showDialog: false }) },
  ],
}).then(appRef => AppInjector.getInstance().setInjector(appRef.injector)).catch(err => console.log(err));
