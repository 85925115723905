import { Injectable, signal } from '@angular/core';
import { PIXEL_ADMIN_MENUS } from '@pixels/client/pages/admin/admin.model';
import { AppInfo } from '@pixels/client/pixels/app-info';
import { AppKeyPixelsAdmin } from '@pixels/universal/model/apps/app-types';
import { PixelsAdminProjectMeta } from '@pixels/universal/model/apps/project-meta/pixels-admin-project-meta';

@Injectable({ providedIn: 'root' })
export class PixelsAdminAppInfo extends AppInfo {
  public readonly appType = AppKeyPixelsAdmin;
  public readonly serviceName = 'PIXELS Admin';
  public readonly appStoreUrl = signal(PixelsAdminProjectMeta.appStoreUrl ?? '');
  public readonly playStoreUrl = signal(`https://play.google.com/store/apps/details?id=${PixelsAdminProjectMeta.appId}`);
  public override readonly adminMenus = PIXEL_ADMIN_MENUS;

  public override readonly enableAppBanner = true;
}
