import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const PATH_MAP = {
  login: {
    name: _('@로그인@'),
    path: 'login',
    fullPath: '/login',
  },
  releaseNote: {
    name: _('@릴리즈노트@'),
    path: 'release-note',
    fullPath: '/release-note',
  },
  deploy: {
    name: _('@배포@'),
    path: 'deploy',
    fullPath: '/deploy',
  },
};
