import { inject, Injectable } from '@angular/core';
import { GlobalSpinnerV2Service } from '@pixels/client/global-spinner/global-spinner-v2.service';
import { HistoryService } from '@pixels/client/navigations/history.service';
import { forkJoin, Observable } from 'rxjs';

@Injectable()
export class AppService {
  private readonly historyService = inject(HistoryService);
  private readonly globalSpinnerService = inject(GlobalSpinnerV2Service);

  public get init$(): Observable<any> {
    return forkJoin([
      this.globalSpinnerService.hidePreloader$,
      this.historyService.init$,
    ]);
  }
}

