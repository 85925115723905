import { TranslateLoader } from '@ngx-translate/core';
import { Lang } from '@pixels/universal/model/i18n/i18n.model';
import { catchError, defer, forkJoin, map, Observable } from 'rxjs';

export class AppI18nLoader implements TranslateLoader {
  getTranslation(lang: Lang): Observable<any> {
    return forkJoin({
      main: defer(() => import(`../i18n/${lang}.json`)).pipe(
        catchError(() => defer(() => import(`../../../../libs/client/src/i18n/en.json`)))
      ),
      projects: defer(() => import(`../../../../libs/client/src/i18n/${lang}.json`)).pipe(
        catchError(() => defer(() => import(`../../../../libs/client/src/i18n/en.json`)))
      ),
      kpop: defer(() => import(`../i18n/kpop/${lang}.json`)).pipe(
        catchError(() => defer(() => import(`../i18n/kpop/en.json`)))
      ),
    }).pipe(
      map(({ main, projects, kpop }) => ({ ...projects.default, ...main.default, ...kpop.default }))
    );
  }
}
